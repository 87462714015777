var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:" fill-height",attrs:{"mini-variant":_vm.mini,"permanent":"","color":"#4F4F4F","app":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('Loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-list-item',{staticClass:"px-2"},[(_vm.mini)?_c('v-list-item-avatar',[_c('v-btn',{attrs:{"width":"30","height":"30","fab":"","small":"","depressed":"","color":"#fff"},on:{"click":function($event){return _vm.showSideMenu()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1):_c('v-list-item-avatar',[_c('v-btn',{staticClass:" font-weight-black",attrs:{"width":"50","height":"50"}},[_vm._v(" "+_vm._s(_vm.logoUser(_vm.user.firstname))+" ")])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:" font-weight-black",staticStyle:{"color":"#828282"}},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user.firstname)+" ")]),_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#828282"}},'v-icon',attrs,false),on),[_vm._v(" mdi-menu-down ")])]}}])},[_c('v-list',_vm._l((_vm.menuLists),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.onHandleProfileMenu(item.link)}}},[_c('v-list-item-title',{staticClass:"font-14"},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),(!_vm.mini)?_c('v-spacer'):_vm._e(),_c('v-btn',{attrs:{"width":"30","height":"30","fab":"","small":"","depressed":"","color":"#fff","d":""},on:{"click":function($event){$event.stopPropagation();return _vm.showSideMenu()}}},[(!_vm.mini)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider'),_c('v-list',{staticClass:"active-side-menu pa-0",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"#F5F5F5","value":_vm.activeMenu,"mandatory":""}},[_vm._l((_vm.permission),function(item,i){return _c('div',{key:i},[_c('v-list-item',{staticClass:"cursor-pointer",class:item.name === _vm.$route.name ? 'active-select-menu' : '',on:{"click":function($event){return _vm.onHandleMenu(item, i, 'main')}},scopedSlots:_vm._u([(item.name === 'Admin')?{key:"default",fn:function(){return [_c('v-list-group',{staticClass:" pa-0 ma-0",attrs:{"value":_vm.activeMenu === 6 ? true : false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-8"},[_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/UserCircle.png'),"alt":""}})],1),_c('v-list-item-content',{staticClass:"font-14 font-weight-bold",staticStyle:{"width":"120px"},style:(item.name === _vm.$route.name
                      ? { color: '#338A68' }
                      : { color: '#828282' })},[_vm._v(" Admin ")])]},proxy:true}],null,true)},_vm._l((item.subMenu),function(subMenu,x){return _c('v-list-item',{key:x,staticClass:"pl-10",attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.onHandleMenu(subMenu, i, 'sub')}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[(subMenu.icon === 'mdi-settings')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/Gear.png'),"alt":""}}):_vm._e(),(subMenu.icon === 'mdi-file-document')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/NewspaperColor.png'),"alt":""}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"font-14 font-weight-bold",style:(subMenu.link === _vm.checkSubActiveMenu(subMenu)
                      ? { color: '#338A68' }
                      : { color: '#828282' }),domProps:{"textContent":_vm._s(subMenu.name)}})],1)}),1)]},proxy:true}:{key:"default",fn:function(){return [_c('v-list-item-icon',{staticClass:"pl-2"},[(item.icon === 'mdi-home')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/HouseLine.png')}}):_vm._e(),(item.icon === 'mdi-file-document')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/File.png')}}):_vm._e(),(item.icon === 'mdi-cube')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/Cube.png')}}):_vm._e(),(item.icon === 'mdi-truck')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/Truck.png')}}):_vm._e(),(item.icon === 'mdi-checkbox-marked-circle')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/CheckCircle.png')}}):_vm._e(),(item.icon === 'mdi-coin')?_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/CurrencyBtc.png')}}):_vm._e()],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:(item.name === _vm.$route.name
                    ? { color: '#338A68' }
                    : { color: '#828282' })},[_vm._v(" "+_vm._s(item.name)+" ")])])]},proxy:true}],null,true)})],1)}),(_vm.user.user_group_id != 2)?_c('div',[_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'MonitoringList' ? 'active-select-menu' : '',attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.goTo('/monitoring')}}},[_c('v-list-item-icon',{staticClass:"pl-2"},[_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/task.png')}})],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:('MonitoringList' === _vm.$route.name
                  ? { color: '#338A68' }
                  : { color: '#828282' })},[_vm._v(" Monitoring ")])])],1)],1):_vm._e(),(_vm.user.user_group_id != 2)?_c('div',[_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'Shipment Control' ||
            _vm.$route.name === 'Shipment Control Export' ||
            _vm.$route.name === 'Shipment Control Import' ||
            _vm.$route.name === 'ControlTruckSupply'
              ? 'active-select-menu'
              : ''},[_c('v-list-group',{attrs:{"value":_vm.$route.name === 'Shipment Control' ||
              _vm.$route.name === 'Shipment Control Export'
                ? true
                : false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-8"},[_c('v-icon',{attrs:{"color":_vm.$route.name === 'Shipment Control' ||
                    _vm.$route.name === 'Shipment Control Export' ||
                    _vm.$route.name === 'Shipment Control Import' ||
                    _vm.$route.name === 'ControlTruckSupply'
                      ? '#338A68'
                      : '#338A68'}},[_vm._v(" mdi-chart-arc ")])],1),_c('v-list-item-content',{staticClass:"font-14 font-weight-bold",staticStyle:{"width":"120px"},style:(_vm.$route.name === 'Shipment Control' ||
                  _vm.$route.name === 'Shipment Control Export' ||
                  _vm.$route.name === 'Shipment Control Import' ||
                  _vm.$route.name === 'ControlTruckSupply'
                    ? { color: '#338A68' }
                    : { color: '#828282' })},[_vm._v(" Control Tower ")])]},proxy:true}],null,false,3736666067)},[_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'Shipment Control' ? 'active-select-menu' : '',attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.goTo('/shipment-control')}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#338a68"}},[_vm._v("mdi-chart-bar")])],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:(_vm.$route.name === 'Shipment Control'
                      ? { color: '#338A68' }
                      : { color: '#828282' })},[_vm._v(" Shipment Control ")])])],1),_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'Shipment Control Export'
                  ? 'active-select-menu'
                  : '',attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.goTo('/shipment-control-export')}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#338a68"}},[_vm._v("mdi-chart-histogram")])],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:(_vm.$route.name === 'Shipment Control Export'
                      ? { color: '#338A68' }
                      : { color: '#828282' })},[_vm._v(" Shipment Control Export ")])])],1),_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'Shipment Control Import'
                  ? 'active-select-menu'
                  : '',attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.goTo('/shipment-control-import')}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#338a68"}},[_vm._v("mdi-chart-line")])],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:(_vm.$route.name === 'Shipment Control Import'
                      ? { color: '#338A68' }
                      : { color: '#828282' })},[_vm._v(" Shipment Control Import ")])])],1),_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'ControlTruckSupply'
                  ? 'active-select-menu'
                  : '',attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.goTo('/control-trucksupply')}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#338a68"}},[_vm._v("mdi-chart-pie")])],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:(_vm.$route.name === 'ControlTruckSupply'
                      ? { color: '#338A68' }
                      : { color: '#828282' })},[_vm._v(" Truck Supply ")])])],1)],1)],1)],1):_vm._e(),_c('div',[_c('v-list-item',{staticClass:"cursor-pointer",class:_vm.$route.name === 'CustomerList' ? 'active-select-menu' : '',attrs:{"link":"","mandatory":""},on:{"click":function($event){return _vm.goTo('/customers')}}},[_c('v-list-item-icon',{staticClass:"pl-2"},[_c('v-img',{attrs:{"width":"25","height":"25","src":require('../../assets/images/menu-icons/Handshake.png')}})],1),_c('v-list-item-content',[_c('span',{staticClass:"font-14 font-weight-bold",style:('CustomerList' === _vm.$route.name
                  ? { color: '#338A68' }
                  : { color: '#828282' })},[_vm._v(" Customers ")])])],1)],1),(!_vm.mini)?_c('div',{staticClass:"version-control"},[_c('div',[_c('span',{staticClass:" font-weight-bold"},[_vm._v("Version:")]),_vm._v(" 0.9.2")]),_c('div',[_c('span',{staticClass:" font-weight-bold"},[_vm._v("Release date:")]),_vm._v(" 22/04/2024 ")])]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }